/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.page-workplace .fs-page-main {
  display: flex;
  flex-direction: column;
}
.page-workplace .wp-hd {
  background: #fff;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.page-workplace .wp-hd .wp-opts-item {
  width: 100px;
  text-align: center;
}
.page-workplace .wp-hd .wp-opts-item .wp-opts-num {
  font-family: DIN-Light;
  font-weight: 700;
  letter-spacing: -0.015em;
  font-size: 30px;
  color: #2d63ff;
}
.page-workplace .wp-hd .wp-opts-item .wp-opts-num.color-red {
  color: #ff3c11;
}
.page-workplace .wp-hd .wp-opts-item .wp-opts-num.color-gray {
  color: #999;
}
.page-workplace .wp-hd .wp-opts-item .wp-opts-item {
  color: #333;
}
.page-workplace .wp-bd {
  background: #fff;
  height: 340px;
  margin-top: 16px;
}
.page-workplace .wp-bd .wp-m-content {
  height: 280px;
}
.page-workplace .wp-bd .my-bank-container {
  display: flex;
  position: relative;
}
.page-workplace .wp-bd .my-bank-container:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  background: #eee;
  left: 46%;
  top: 0;
  transform: translateX(-50%);
}
.page-workplace .wp-bd .my-bank-container .my-bank-container-left {
  width: 46%;
}
.page-workplace .wp-bd .my-bank-container .my-bank-container-right {
  width: 54%;
  height: 100%;
  overflow: hidden;
}
.page-workplace .wp-ft {
  height: 400px;
  margin-top: 16px;
  display: flex;
}
.page-workplace .wp-ft .wp-m-content {
  height: 300px;
}
.page-workplace .wp-ft .wp-ft-left {
  width: 50%;
  background: #fff;
  margin-right: 8px;
}
.page-workplace .wp-ft .wp-ft-left .trade-wrapper {
  height: 300px;
  background: url('/img/workplace/trade_wrapper.png') no-repeat left center / auto 220px;
  display: flex;
  align-items: center;
}
.page-workplace .wp-ft .wp-ft-left .trade-main {
  position: relative;
  height: 100%;
  width: 100%;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .trade-block {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .trade-block .trade-block-item {
  text-align: center;
  width: 0;
  flex: 1;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .trade-block .trade-block-title {
  font-size: 14px;
  line-height: 1;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .trade-block .trade-block-num {
  font-family: DIN-Light;
  font-weight: 700;
  letter-spacing: -0.015em;
  font-size: 26px;
  line-height: 1;
  padding-top: 8px;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .trade-block.trade-block-light .trade-block-title {
  color: #fff;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .trade-block.trade-block-light .trade-block-num {
  color: #392d5c;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .trade-block.trade-block-dark .trade-block-title {
  color: #949494;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .trade-block.trade-block-dark .trade-block-num {
  color: #666;
  font-size: 20px;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .ex-text-block {
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .ex-text-block .ex-text-block-title {
  font-size: 12px;
  color: #666;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .ex-text-block .ex-text-block-num {
  padding-top: 4px;
  font-family: DIN-Light;
  font-weight: 700;
  letter-spacing: -0.015em;
  font-size: 20px;
  color: #333;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .block-1-1 {
  width: 218px;
  height: 68px;
  position: absolute;
  top: 40px;
  left: 0;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .block-1-2 {
  width: 192px;
  height: 68px;
  position: absolute;
  top: 40px;
  left: 218px;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .block-2-1 {
  width: 178px;
  height: 68px;
  position: absolute;
  top: 116px;
  left: 20px;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .block-2-2 {
  width: 212px;
  height: 68px;
  position: absolute;
  top: 116px;
  left: 198px;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .block-3-1 {
  width: 138px;
  height: 68px;
  position: absolute;
  top: 192px;
  left: 40px;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .block-3-2 {
  width: 232px;
  height: 68px;
  position: absolute;
  top: 192px;
  left: 178px;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .ex-text-block-1 {
  position: absolute;
  width: 70px;
  height: 50px;
  left: 450px;
  top: 80px;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .ex-text-block-2 {
  position: absolute;
  width: 70px;
  height: 50px;
  left: 450px;
  top: 170px;
}
.page-workplace .wp-ft .wp-ft-left .trade-main .ex-text-block-3 {
  position: absolute;
  width: 70px;
  height: 50px;
  left: 540px;
  top: 125px;
}
@media screen and (max-width: 1600px) {
  .page-workplace .wp-ft .wp-ft-left .trade-wrapper {
    background: url('/img/workplace/trade_wrapper.png') no-repeat left center / auto 154px;
  }
  .page-workplace .wp-ft .wp-ft-left .trade-main {
    transform: scale(0.7);
    transform-origin: left center;
  }
}
.page-workplace .wp-ft .wp-ft-right {
  width: 50%;
  background: #fff;
  margin-left: 8px;
}
.page-workplace .wp-ft .wp-ft-right .ov {
  padding-top: 40px;
}
.page-workplace .wp-module {
  padding: 20px 30px;
}
.page-workplace .wp-module .wp-m-title {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  line-height: 1;
}
.page-workplace .ov {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
}
.page-workplace .ov .ov-opts-item {
  color: #333;
  width: 25%;
  text-align: center;
  margin: 30px 0;
}
.page-workplace .ov .ov-opts-item .ov-opts-num {
  font-family: DIN-Light;
  font-weight: 700;
  letter-spacing: -0.015em;
  font-size: 30px;
  line-height: 1;
  padding: 14px 0;
}
.page-workplace .ov .ov-opts-item .ov-opts-comp {
  color: #999;
}
.page-workplace .ov .ov-opts-item .ov-opts-comp .status-up {
  color: #ff3c11;
}
.page-workplace .ov .ov-opts-item .ov-opts-comp .status-up:after {
  content: '↑';
  font-size: 120%;
  margin-left: 2px;
}
.page-workplace .ov .ov-opts-item .ov-opts-comp .status-down {
  color: #24b08a;
}
.page-workplace .ov .ov-opts-item .ov-opts-comp .status-down:after {
  content: '↑';
  font-size: 120%;
  margin-left: 2px;
}
.page-workplace .echarts {
  width: 100%;
  height: 100%;
}
